<template>
  <p class="clearfix mb-0" v-show="showFooter">
    <span class="float-md-right d-none d-md-block">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://www.pisecuriti.com"
        target="_blank"
      >普瑞数安</b-link>
      <span class="d-none d-sm-inline-block">, 版权所有</span>

    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
  data() {
    return {
      showFooter: false,
    }
  }
}
</script>
