<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >
    <!-- Content Left -->
    <b-col
      class="content-header-left"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <!-- <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h2> -->
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item
                v-if="workbenNav"
                to="/workbench"
              >
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="(item,k) in $route.meta.breadcrumb"
                :id="k == $route.meta.breadcrumb.length-1 ? 'detail-breadcrumb' : ''"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col
      class="content-header-right text-md-right d-md-block d-none"
      md="3"
      cols="12"
    >
      <div class="header-right">
        <div
          v-if="workbenNav"
          class="header-icon"
        >
          <router-link to="/workbench">
            <el-tooltip
              content="工作台"
              placement="bottom"
              effect="light"
            >
              <i class="iconfont icon-gongzuotai1" />
            </el-tooltip>
          </router-link>
        </div>
        <div class="header-icon">
          <router-link to="/quick-start">
            <el-tooltip
              content="快速导航"
              placement="bottom"
              effect="light"
            >
              <i class="iconfont icon-kuaijiecaozuo" />
            </el-tooltip>
          </router-link>
        </div>
        <!--        <NotificationDropdown />-->
        <el-popover
          :popper-class="'msg-popper'"
          placement="bottom"
          width="400"
          trigger="click"
          @show="propverShow"
          @hide="propverHide"
        >

          <div class="header-scroll-wrap">
            <div class="dropdown-header d-flex">
              <h4
                style="font-weight: 700"
                class="notification-title mb-0 mr-auto"
              >
                通知
              </h4>
              <b-badge
                pill
                variant="light-primary"
              >
                {{ unReadCount }} New
              </b-badge>
            </div>
            <VuePerfectScrollbar
              id="followData"
              class="msg-scroll"
              @ps-scroll-y="scrollHandle"
            >
              <b-list-group>
                <b-list-group-item
                  v-for="(item,index) in msgList"
                  v-if="item.status === 1"
                  :key="index"
                  class="d-flex justify-content-between align-items-center"
                >
                  <el-badge
                    v-if="item.status ===1 "
                    is-dot
                  >
                    <span
                      class="msg-centent"
                      v-html="item.content"
                    />
                  </el-badge>
                  <span
                    v-else
                    class="msg-centent"
                    v-html="item.content"
                  />
                  <el-button
                    class="ml10"
                    v-if="item.status === 1"
                    size="mini"
                    @click="updateMsgStatus(item)"
                  >
                    标记已读
                  </el-button>
                </b-list-group-item>
              </b-list-group>
              <p
                v-show="msgLoading"
                class="text-center"
              >
                <b-spinner
                  class="mr-1 text-center"
                  :variant="'primary'"
                />
              </p>
            </VuePerfectScrollbar>
            <div class="read-all">
              <el-button
                v-show="unReadCount>0"
                class="btn-block text-center"
                size="mini"
                type="primary"
                @click="updateAllStatus"
              >
                全部标记已读
              </el-button>
              <p
                v-show="unReadCount==0"
                class="text-center"
              >
                没有未读消息
              </p>
            </div>
          </div>
          <el-badge
            slot="reference"
            :hidden="unReadCount===0"
            class="header-icon"
            :value="unReadCount"
            :max="99"
          >
            <i class="iconfont icon-tongzhi" />
          </el-badge>
        </el-popover>
        <div
          v-if="docNav"
          class="header-icon"
        >
          <el-tooltip
            content="文档管理"
            effect="light"
            placement="top"
          >
            <router-link to="/docment">
              <i class="iconfont icon-wendangguanli-xiangmuleiwendang" />
            </router-link>
          </el-tooltip>

        </div>
        <div class="header-icon">
          <el-tooltip
            :content="fullscreen ? '退出全屏' : '全屏'"
            effect="light"
            placement="top"
          >
            <i
              class="iconfont icon-quanping"
              @click="screen"
            />
          </el-tooltip>

        </div>
        <b-dropdown
          variant="link"
          no-caret
          toggle-class="p-0"
          right
        >
          <template #button-content>
            <b-avatar
              :src="userData.avatar"
              variant="light-primary"
              badge
              class="badge-minimal"
              badge-variant="success"
            >
              <feather-icon
                v-if="!userData.avatar"
                icon="UserIcon"
                size="22"
              />
            </b-avatar>
          </template>
          <b-dropdown-item disabled>
            <span>{{ userData.actualName || userData.email }}</span>
          </b-dropdown-item>

          <b-dropdown-divider />

          <b-dropdown-item
            :to="{ name: 'profile' }"
            link-class="d-flex align-items-center"
          >
            <feather-icon
              size="16"
              icon="UserIcon"
              class="mr-50"
            />
            <span>个人设置</span>
          </b-dropdown-item>
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="logout"
          >
            <feather-icon
              size="16"
              icon="LogOutIcon"
              class="mr-50"
            />
            <span>退出登陆</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>

    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BButton,
  BAvatar,
  BListGroup,
  BListGroupItem,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { error, success, enterpriseEvent } from '@core/utils/utils'
import { getUserData } from '@/auth/utils'
import {
  listData, UpdateAllUnReadStatus, UpdateMessageReadStatus, GetMessageReadCount,
} from '@/api/system/notify/notify'
import NotificationDropdown from '@/@core/layouts/components/app-navbar/components/NotificationDropdown'

export default {
  directives: {
    Ripple,
  },
  components: {
    VuePerfectScrollbar,
    // eslint-disable-next-line vue/no-unused-components
    BBadge,
    BListGroup,
    BListGroupItem,
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BAvatar,
    NotificationDropdown,
  },
  data() {
    const userData = getUserData()
    // console.log(localStorage.getItem('navMenus'))
    return {
      userData,
      avatarText,
      fullscreen: false,
      count: 0,
      messageLoading: false,
      msgList: [],
      msgLoading: false,
      msgTotal: 0,
      listQuery: {
        page: 1,
        pageSize: 10,
        SearchStr: '',
        status: 1,
        messageType: 2,
      },
      unReadCount: 0,
      docNav: false,
      workbenNav: false,
    }
  },
  created() {
    enterpriseEvent.$on('docNav', data => {
      this.docNav = data
    })
    enterpriseEvent.$on('workbenNav', data => {
      this.workbenNav = data
    })
    enterpriseEvent.$on('updateUserInfo', () => {
      this.userData = getUserData()
    })
    this.getMessageReadCount()
  },
  methods: {
    screen() {
      const element = document.documentElement
      const isFullScreen = document.fullScreen
        || document.mozFullScreen
        || document.webkitIsFullScreen
        || document.msFullscreenElement
      this.fullscreen = isFullScreen
      if (isFullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      } else if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.msRequestFullscreen) {
        // IE11
        element.msRequestFullscreen()
      }
      // this.fullscreen = !this.fullscreen
    },
    getMessageReadCount() {
      GetMessageReadCount({ messageType: 2 }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.unReadCount = resData.data.unReadCount
        }
      })
    },
    updateAllStatus() {
      UpdateAllUnReadStatus().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          success(resData.msg)
          this.msgList.forEach(m => {
            m.status = 2
          })
          this.getMessageReadCount()
        } else {
          error(resData.msg)
        }
      })
    },
    updateMsgStatus(item) {
      const ids = []
      ids.push(item.id)
      UpdateMessageReadStatus({ ids }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          success(resData.msg)
          item.status = 2
          this.getMessageReadCount()
        } else {
          error(resData.msg)
        }
      })
    },
    getMsg() {
      this.msgLoading = true
      listData(this.listQuery).then(res => {
        this.msgLoading = false
        res.data.data.list.forEach(d => {
          this.msgList.push(d)
        })
        // this.msgList = res.data.data.list
        this.msgTotal = Number(res.data.data.total)
        this.unReadCount = this.msgTotal
        if (this.msgList.length < this.msgTotal) {
          this.listQuery.page++
        }
      })
    },
    scrollHandle(e) {
      // 滚动分页加载
      const divHeight = document.getElementById('followData').offsetHeight
      const nScrollHeight = document.getElementById('followData').scrollHeight
      const nScrollTop = document.getElementById('followData').scrollTop
      if (nScrollTop + divHeight > nScrollHeight) {
        setTimeout(() => {
          if (this.msgList.length < this.msgTotal) {
            this.getMsg()
          }
        }, 500)
      }
    },
    propverHide() {
      document.body.style.overflowY = ''
    },
    propverShow() {
      document.body.style.overflowY = 'hidden'
      this.msgList = []
      this.listQuery.page = 1
      this.getMsg()
    },
    logout() {
      this.$confirm('是否确认退出吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
        localStorage.removeItem('userData')
        localStorage.removeItem('navMenus')
        localStorage.removeItem('buttonPerms')
        this.$ability.update(initialAbility)
        this.$router.push({ name: 'auth-login' })
      })
    },
  },
}
</script>
<style lang="scss">
  .navbar-container{
    padding: 0 1.4rem !important;
  }
  .no-more{
    color: #0d40b2;
    text-align: center;
  }
  .read-all{
    width: 80%;
    margin: 0 auto;
    padding: 10px;
  }
  .icon-tongzhi{
    cursor: pointer;
  }
  .msg-popper{
    padding: 0 !important;
    .list-group-item:last-child {
      border-bottom: none !important;
    }
  }
  .msg-centent{
    font-size: 12px;
  }
  .msg-scroll{
    max-height: 400px;
    border-bottom: 1px solid #ebe9f1;
  }
  .header-icon{margin-right: 30px}
  .mr40{margin-right: 40px}
  .header-right{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .el-icon-message{
      font-size: 20px;
      color: #0b379a;
    }
    .iconfont{
      font-size: 20px;
      color: #0b379a;
    }
  }
  [dir] .breadcrumb {
    padding: 0.5rem 1rem;
  }
</style>
