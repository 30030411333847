<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <span class="brand-logo">
                <b-img
                  :src="appLogoImage"
                  alt="logo"
                />
              </span>
              <!--              <img :src="appLogoImage" width="120" class="img-fluid" alt="">-->
              <h2 class="brand-text">
                {{ appName }}
              </h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { enterpriseEvent } from '@core/utils/utils'
import { getEnterpriseInfo } from '@/api/enterprise/enterprise'
import { MenuNav } from '@/api/system/system'
import useVerticalNavMenu from './useVerticalNavMenu'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      navMenuItems: [],
      appName: '',
      appLogoImage: '',
    }
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    // const { appName, appLogoImage } = $themeConfig.app
    return {
      // navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      // appName,
      // appLogoImage,
    }
  },
  created() {
    this.getResource()
    this.getEnterpriseInfo()
  },
  mounted() {
    enterpriseEvent.$on('enterpriseInfo', data => {
      this.appName = data.shortName
      const { baseUrl } = window.g
      if (!data.logoUrl.includes(baseUrl)) {
        this.appLogoImage = baseUrl + data.logoUrl
      } else {
        this.appLogoImage = data.logoUrl
      }
      document.title = `${this.appName} - 个人信息合规管理平台`
    })
  },
  methods: {
    treeToList(datas) {
      const arr = []
      formateData(datas, 0)

      function formateData(datas, level) {
        var level = level || 0
        level++
        for (const i in datas) {
          arr.push({
            id: datas[i].id,
            component: datas[i].component,
            type: datas[i].type,
            name: datas[i].name,
          })
          if (datas[i].childResources) {
            formateData(datas[i].childResources, level)
          }
        }
      }
      return arr
    },
    getResource() {
      MenuNav()
        .then(res => {
          const resData = res.data
          // var resourceLists = treeDataTranslate(resData.data)
          //  头部导航 文档管理 工作台
          let docNav = false
          let workbenNav = false

          const resourceLists = resData.data
          resourceLists.forEach(r => {
            // 添加导航头
            if (r.type === 0) {
              this.navMenuItems.push({
                header: r.name,
                resource: 'header-1',
                action: 'manage',
              })
            }
            // 一级 添加菜单
            if (r.childResources) {
              r.childResources.forEach(c => {
                if (c.childResources) {
                  const children = []
                  c.childResources.forEach(child => {
                    if (child.type === 2) {
                      children.push({
                        title: child.name,
                        route: child.component || child.path,
                      })
                    }
                  })
                  const itemObj = {
                    title: c.name,
                    icon: c.icon,
                  }
                  if (children.length > 0) {
                    itemObj.children = children
                  } else {
                    itemObj.route = c.component || c.path
                  }
                  if (c.type === 2) {
                    this.navMenuItems.push(itemObj)
                  }
                }
              })
            }
          })
          // localStorage.setItem('navMenus', JSON.stringify(this.navMenuItems))
          // 保存按钮权限
          const buttonPerms = []
          const listData = this.treeToList(resData.data)
          const twoLevelMenus = []
          listData.forEach(l => {
            // 文档管理
            if (l.name === '文档管理') {
              docNav = true
            }
            // 工作台
            if (l.name === '工作台') {
              workbenNav = true
            }
            if (l.type === 5 && l.component) {
              buttonPerms.push(l.component)
            }
            // 添加二级菜单
            if (l.type === 2) {
              twoLevelMenus.push(l)
            }
          })
          localStorage.setItem('twoLevelMenus', JSON.stringify(twoLevelMenus))
          localStorage.setItem('buttonPerms', JSON.stringify(buttonPerms))
          // 广播事件
          enterpriseEvent.$emit('docNav', docNav)
          enterpriseEvent.$emit('workbenNav', workbenNav)
        })
    },
    getEnterpriseInfo() {
      getEnterpriseInfo()
        .then(res => {
          const enterpriseInfo = { ...res.data.data }
          this.appName = enterpriseInfo.shortName
          const { baseUrl } = window.g
          if (!enterpriseInfo.logoUrl.includes(baseUrl)) {
            this.appLogoImage = baseUrl + enterpriseInfo.logoUrl
          } else {
            this.appLogoImage = enterpriseInfo.logoUrl
          }
          document.title = `${this.appName} - 个人信息合规管理平台`
        })
    },
  },
}
</script>

<style lang="scss">
    @import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";

    .nav-item svg {
        opacity: 1 !important;
    }
</style>
